import React, { useState, useEffect } from 'react';
import { array, bool, func, number } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { parse } from '../../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import businessCategoryConfig from '../../../business-category-config';
import { MainFiltersForm } from '../../../forms';
import {
  NamedLink,
  IconInfo,
  IconMap,
  IconFilters,
  Modal,
  IconSearchThird,
} from '../../../components';
import FilterModal from './FilterModal/FilterModal';

import css from './FilterContainer.css';

const MIN_PAGE_Y_OFFSET = 100;
const MIN_PAGE_Y_OFFSET_MOBILE = 84;
const MOBILE_VIEWPORT = 550;
const HEIGHT_SEARCH_BUTTON_PRIMARY = 46;
const HEIGHT_SEARCH_BUTTON_SECONDARY = 66;

const FilterContainer = ({
  listingsAreLoaded,
  onManageDisableScrolling,
  onMapIconClick,
  providers,
  totalItems,
  intl,
  suggestions,
  location,
  history,
}) => {
  const {
    address,
    keywords,
    businessCategory,
    origin,
    countryId,
    homeDelivery,
    providerId,
    sort,
    start,
    end,
  } = parse(location.search);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const sortFilter = sort !== 'relevance' && sort !== 'createdAt' ? sort : undefined;
  const filters = [countryId, homeDelivery, providerId, sortFilter, start || end];
  const filterNumber = filters.filter(item => !!item).length;
  const filterClasses = classNames(css.filtersBtn, { [css.borderFilters]: !!filterNumber });
  const subcategoryLabel =
    businessCategory && businessCategoryConfig.find(({ key }) => key === businessCategory);

  const handleClick = () => {
    setIsMobileModalOpen(true);
  };

  const searchButtonText =
    !address && !keywords && !businessCategory && !origin ? (
      <span className={css.searchBtnText}>
        <FormattedMessage id="FilterContainer.search" />
      </span>
    ) : (
      <div>
        <p className={css.searchBtnText}>
          <strong>{keywords || subcategoryLabel?.label}</strong>
        </p>
        <p className={css.searchBtnText}>{address}</p>
      </div>
    );

  useEffect(() => {
    const heightSearchButton =
      (keywords || subcategoryLabel?.label) && address
        ? HEIGHT_SEARCH_BUTTON_SECONDARY
        : HEIGHT_SEARCH_BUTTON_PRIMARY;
    const offset =
      window.innerWidth > MOBILE_VIEWPORT
        ? MIN_PAGE_Y_OFFSET
        : MIN_PAGE_Y_OFFSET_MOBILE + heightSearchButton;
    const handleScroll = () => setIsScrolled(window.pageYOffset > offset);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={css.filterContainer}>
      <div className={css.filterWrapper}>
        {!isMobileModalOpen && (
          <MainFiltersForm
            intl={intl}
            location={location}
            history={history}
            categoriesSuggestions={suggestions}
            formClassName={classNames(css.formDesktop, { [css.wrapper]: isScrolled })}
          />
        )}
        {isScrolled && <div className={css.emptyContainer} />}
        <div  className={classNames(css.searchBtnContainer, { [css.mobileWrapper]: isScrolled })}>
        <button
          onClick={handleClick}
          className={css.searchBtn}
        >
          <IconSearchThird />
          {searchButtonText}
        </button>
        </div>
        {isScrolled && <div className={css.emptyMobileContainer} />}
        <Modal
          id="FilterContainer.mainFiltersForm"
          contentClassName={css.modalContent}
          isOpen={isMobileModalOpen}
          onClose={() => setIsMobileModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          {isMobileModalOpen && (
            <MainFiltersForm
              intl={intl}
              location={location}
              history={history}
              categoriesSuggestions={suggestions}
              onModalClose={() => setIsMobileModalOpen(false)}
              formContainerClassName={css.formContainerMobile}
              buttonSearchClassName={css.btnSearch}
              listingInputClassName={css.input}
              locationInputClassName={css.input}
              isMobileModalOpen={isMobileModalOpen}
            />
          )}
        </Modal>

        <div className={css.actionContainer}>
          <div className={css.filtersWrapper}>
            {!!filterNumber && <div className={css.filterDot}>{filterNumber}</div>}
            <button
              type="button"
              className={filterClasses}
              onClick={() => setIsFilterModalOpen(true)}
            >
              <IconFilters className={css.icon} />
              <FormattedMessage id="MainPanel.filters" className={css.textFiltersBtn} />
            </button>
          </div>
          <button type="button" className={css.mapBtn} onClick={onMapIconClick}>
            <IconMap className={css.icon} />
            <FormattedMessage id="MainPanel.openMapView" className={css.textFiltersBtn} />
          </button>
        </div>
      </div>

      <div className={css.infoContainer}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: totalItems }}
              />
            </span>
          </div>
        ) : null}
        <NamedLink
          className={css.productsOrderLink}
          name="FAQPage"
          to={{ hash: '#q10', search: '?q=10' }}
          target="_blank"
          type="button"
        >
          <FormattedMessage id="SearchFiltersPrimary.productsOrder" />
          <IconInfo className={css.infoIcon} />
        </NamedLink>
      </div>

      <FilterModal
        isOpen={isFilterModalOpen}
        onCloseModal={() => setIsFilterModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        providers={providers}
        closeModal={() => setIsFilterModalOpen(false)}
      />
    </div>
  );
};

FilterContainer.propTypes = {
  listingsAreLoaded: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onMapIconClick: func.isRequired,
  providers: array.isRequired,
  totalItems: number.isRequired,
};

export default compose(withRouter, injectIntl)(FilterContainer);
