const defaultSearches = [
  {
    countryId: 'pl',
    keyword: 'namioty kempingowe',
    category: 'camping',
    subcategory: 'camping_tent',
    exact: true,
  },
  {
    countryId: 'pl',
    keyword: 'rowery gravel',
    category: 'bikes',
    subcategory: 'bikes_gravel',
    exact: true,
  },
  {
    countryId: 'pl',
    keyword: 'rowery elektryczne',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  {
    countryId: 'pl',
    keyword: 'rowery górskie',
    category: 'bikes',
    subcategory: 'bikes_mountain',
    exact: true,
  },
  {
    countryId: 'pl',
    keyword: 'rowery szosowe',
    category: 'bikes',
    subcategory: 'bikes_road',
    exact: true,
  },
  {
    countryId: 'pl',
    keyword: 'kampery',
    category: 'camping',
    subcategory: 'camping_campers',
    exact: true,
  },
  {
    countryId: 'hu',
    keyword: 'elektromos kerékpárok',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  {
    countryId: 'hu',
    keyword: 'kemping sátrak',
    category: 'camping',
    subcategory: 'camping_tent',
    exact: true,
  },
  {
    countryId: 'hu',
    keyword: 'mountain bikes',
    category: 'bikes',
    subcategory: 'bikes_mountain',
    exact: true,
  },
  {
    countryId: 'hu',
    keyword: 'országúti kerékpárok',
    category: 'bikes',
    subcategory: 'bikes_road',
    exact: true,
  },
  {
    countryId: 'hu',
    keyword: 'SUP',
    category: 'watersports',
    subcategory: 'watersports_sup',
    exact: true,
  },
  //cz
  {
    countryId: 'cz',
    keyword: 'autostan',
    category: 'camping',
    subcategory: 'camping_tent',
    exact: true,
  },
  {
    countryId: 'cz',
    keyword: 'Stand Up Paddleboardy',
    category: 'watersports',
    subcategory: 'watersports_sup',
    exact: true,
  },
  {
    countryId: 'cz',
    keyword: 'elektrokolo',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  {
    countryId: 'cz',
    keyword: 'gravel kolo',
    category: 'bikes',
    subcategory: 'bikes_gravel',
    exact: true,
  },
  {
    countryId: 'cz',
    keyword: 'horské kolo',
    category: 'bikes',
    subcategory: 'bikes_mountain',
    exact: true,
  },
  {
    countryId: 'cz',
    keyword: 'horské elektrokolo',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: false,
  },
  // {
  //   countryId: 'cz',
  //   keyword: 'snowboard',
  //   category: 'wintersports',
  //   subcategory: 'wintersports_snowboard',
  //   exact: true,
  // },
  // {
  //   countryId: 'cz',
  //   keyword: 'snowboardové boty',
  //   category: 'wintersports',
  //   subcategory: 'wintersports_snowboard',
  //   exact: false,
  // },
  // {
  //   countryId: 'cz',
  //   keyword: 'lyže',
  //   category: 'wintersports',
  //   subcategory: 'wintersports_alpineskiing',
  //   exact: true,
  // },
  // {
  //   countryId: 'cz',
  //   keyword: 'běžky',
  //   category: 'wintersports',
  //   subcategory: 'wintersports_crosscountry',
  //   exact: true,
  // },
  // {
  //   countryId: 'cz',
  //   keyword: 'běžecký pás',
  //   category: 'fitness',
  //   subcategory: 'fitness_cardio',
  //   exact: false,
  // },
  // lt
  {
    countryId: 'lt',
    keyword: 'kalnų dviračiai',
    category: 'bikes',
    subcategory: 'bikes_mountain',
    exact: true,
  },
  {
    countryId: 'lt',
    keyword: 'Žvyrkelių dviračiai',
    category: 'bikes',
    subcategory: 'bikes_gravel',
    exact: true,
  },
  {
    countryId: 'lt',
    keyword: 'dviratis',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  {
    countryId: 'lt',
    keyword: 'irklentės',
    category: 'watersports',
    subcategory: 'watersports_sup',
    exact: true,
  },
  {
    countryId: 'lt',
    keyword: 'treniruoklis',
    category: 'bikes',
    subcategory: 'bikes_treadmill',
    exact: true,
  },
  // lv
  {
    countryId: 'lv',
    keyword: 'kempinga teltis',
    category: 'camping',
    subcategory: 'camping_tent',
    exact: true,
  },
  {
    countryId: 'lv',
    keyword: 'kardiovaskulārās treniņa iekārtas',
    category: 'fitness',
    subcategory: 'fitness_cardio',
    exact: true,
  },
  {
    countryId: 'lv',
    keyword: 'tenisa rakete',
    category: 'recreation',
    subcategory: 'recreation_racketsports',
    exact: true,
  },
  {
    countryId: 'lv',
    keyword: 'velotrenažieris',
    category: 'bikes',
    subcategory: 'bikes_treadmill',
    exact: true,
  },
  {
    countryId: 'lv',
    keyword: 'velosipēds',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  // ee
  {
    countryId: 'ee',
    keyword: 'matkatelgid',
    category: 'camping',
    subcategory: 'camping_tent',
    exact: true,
  },
  {
    countryId: 'ee',
    keyword: 'elektrirattad',
    category: 'bikes',
    subcategory: 'bikes_electric',
    exact: true,
  },
  {
    countryId: 'ee',
    keyword: 'kardiotreeningu varustus',
    category: 'fitness',
    subcategory: 'fitness_cardio',
    exact: true,
  },
  {
    countryId: 'ee',
    keyword: 'turbotrenažöör',
    category: 'bikes',
    subcategory: 'bikes_treadmill',
    exact: true,
  },

];

export default defaultSearches;
