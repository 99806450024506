import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { func, string, shape, bool, array } from 'prop-types';

import routeConfiguration from '../../../../routeConfiguration';
import config from '../../../../config';
import { countryId as domainCountryId } from '../../../../marketplace-custom-config';
import { createResourceLocatorString } from '../../../../util/routes';
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { getDistanceInKilometers } from '../../../../util/maps';
import { decodeLatLng, parse } from '../../../../util/urlHelpers';
import { Accordion, Checkbox, Modal, RadioButton } from '../../../../components';
import {
  sortCountryProviders,
  sortDistanceProviders,
  sortSelectedProviders,
} from './FilterModal.helpers';
import DateFilter from './DateFilter/DateFilter';
import ClearButton from './ClearButton/ClearButton';

import css from './FilterModal.css';

const DEFAULT_NUMBER_OF_PROVIDERS = 5;

const SEARCH_PAGE = 'SearchPage';
const RELEVANCE_VALUE = 'relevance';
const CREATE_AT = 'createdAt';
const FORM_FIELDS = {
  COUNTRY: 'countryField',
  PROVIDER: 'providerField',
  HOME_DELIVERY: 'homeDeliveryField',
  SORT: 'sortField',
  DATES: 'datesField',
};

const isSelectedField = (key, value) => {
  return key === value ? true : key === CREATE_AT && !value;
};

const FilterModal = props => {
  const {
    className,
    rootClassName,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    intl,
    providers,
    closeModal,
    location,
    history,
  } = props;
  const {
    keywords,
    homeDelivery,
    countryId,
    providerId,
    sort,
    start,
    end,
    page,
    exact,
    ...rest
  } = parse(location.search);
  const origin = rest.origin && decodeLatLng(rest.origin);
  const originPoint = origin ? { lat: origin.lat, lng: origin.lng } : null;
  const providersWithDistance = originPoint
    ? providers.map(provider => {
        const location = provider?.attributes.location;
        return {
          ...provider,
          distance: location && Math.round(getDistanceInKilometers(originPoint, { ...location })),
        };
      })
    : null;

  const sortedDistanceProviders = originPoint
    ? sortDistanceProviders(providersWithDistance)
    : sortCountryProviders(providers, domainCountryId);

  const sortedSelectedProviders = parse(location.search).providerId
    ? sortSelectedProviders(sortedDistanceProviders, parse(location.search).providerId)
    : sortedDistanceProviders;

  const countryProviderList = countryId
    ? sortedSelectedProviders.filter(providers => providers.attributes.countryId === countryId)
    : sortedSelectedProviders;
  const defaultProviders = countryProviderList.slice(0, DEFAULT_NUMBER_OF_PROVIDERS);
  const [countryProviders, setCountryProviders] = useState([]);
  const [providersDisplayed, setProvidersDisplayed] = useState([]);
  const [isExpandedProviderList, setIsExpandedProviderList] = useState(false);
  const classes = classNames(rootClassName || css.root, className);

  const initialValues = {
    homeDeliveryField: homeDelivery || false,
    countryField: countryId,
    providerField: providerId || '',
    sortField: keywords ? RELEVANCE_VALUE : sort,
    // datesField: {
    //   startDate: start ? new Date(start) : null,
    //   endDate: end ? new Date(end) : null,
    // },
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const watchCountryField = watch(FORM_FIELDS.COUNTRY);

  const onSubmit = data => {
    const {
      homeDeliveryField,
      countryField,
      providerField,
      sortField,
      // datesField: { startDate, endDate },
    } = data;

    const homeDeliveryMaybe = homeDeliveryField ? { homeDelivery: homeDeliveryField } : {};
    const countryMaybe = countryField ? { countryId: countryField } : {};
    const providerMaybe = providerField ? { providerId: providerField } : {};
    const sortMaybe = sortField ? { sort: sortField } : {};
    // const startMaybe = startDate ? { start: startDate.toISOString().split('T')[0] } : {};
    // const endMaybe = endDate ? { end: endDate.toISOString().split('T')[0] } : {};

    const searchParams = {
      ...rest,
      exact,
      keywords,
      // ...startMaybe,
      // ...endMaybe,
      ...homeDeliveryMaybe,
      ...countryMaybe,
      ...providerMaybe,
      ...sortMaybe,
    };

    history.push(createResourceLocatorString(SEARCH_PAGE, routeConfiguration(), {}, searchParams));

    closeModal();
  };

  const handleClearFilterClick = () => {
    reset({
      homeDeliveryField: false,
      countryField: config.custom.countryId,
      providerField: '',
      sortField: '',
      // datesField: {
      //   startDate: null,
      //   endDate: null,
      // },
    });

    history.push(
      createResourceLocatorString(SEARCH_PAGE, routeConfiguration(), {}, { keywords, ...rest })
    );
    closeModal();
  };

  const handleSelectProvider = (value, id, onChange) => {
    const selectedProviders = value ? value.split(',') : [];
    const updateSelectedProviders = selectedProviders.includes(id)
      ? selectedProviders.filter(item => item !== id)
      : [...selectedProviders, id];
    onChange(updateSelectedProviders.toString());
  };

  const handleExpandProvidersClick = event => {
    event.preventDefault();
    setIsExpandedProviderList(prevState => !prevState);
  };

  const setErrorDates = value => [setError(FORM_FIELDS.DATES, value)];

  useEffect(() => {
    setValue(FORM_FIELDS.SORT, keywords && !exact ? RELEVANCE_VALUE : sort);
  }, [keywords, exact]);

  useEffect(() => {
    setValue(FORM_FIELDS.PROVIDER, providerId);
    setValue(FORM_FIELDS.COUNTRY, countryId);
  }, [parse(location.search).countryId, parse(location.search).providerId]);

  useEffect(() => {
    setCountryProviders(countryProviderList);
    setProvidersDisplayed(defaultProviders);
  }, [providers, parse(location.search).origin, parse(location.search).providerId]);

  useEffect(() => {
    const providersOfSelectedCountry = sortedSelectedProviders.filter(
      providers => providers.attributes.countryId === watchCountryField
    );
    setCountryProviders(watchCountryField ? providersOfSelectedCountry : sortedSelectedProviders);
  }, [watchCountryField]);

  useEffect(() => {
    const list = countryProviders.slice(0, DEFAULT_NUMBER_OF_PROVIDERS);
    const providerList = isExpandedProviderList ? countryProviders : list;
    setProvidersDisplayed(providerList);
  }, [countryProviders, isExpandedProviderList]);

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--mobileHeight', `${vh}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      id={'FilterModal'}
      className={css.modal}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
        <div className={css.headContainer}>
          <ClearButton buttonClassName={css.btnClearMobile} onClick={handleClearFilterClick} />
          <p className={css.modalTitle}>
            <FormattedMessage id="FilterModal.modalTitle" />
          </p>
        </div>
        <div className={css.formContent}>
          {/* <Controller
            control={control}
            name={FORM_FIELDS.DATES}
            render={({ field: { onChange, value, onBlur, name } }) => (
              <DateFilter
                intl={intl}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                setErrorDates={setErrorDates}
              />
            )}
          /> */}
          <Controller
            control={control}
            name={FORM_FIELDS.PROVIDER}
            render={({ field: { onChange, value } }) => {
              return (
                <section>
                  <p className={css.sectionTitle}>
                    <FormattedMessage id="FilterModal.provider" />
                  </p>
                  <div className={css.providerContainer}>
                    {providersDisplayed.map(({ attributes: { name }, id: { uuid }, distance }) => {
                      const selectedProviders = value ? value.split(',') : [];
                      const isSelected = selectedProviders.includes(uuid);
                      return (
                        <div className={css.itemContainer}>
                          <Checkbox
                            key={uuid}
                            label={name}
                            checked={isSelected}
                            onChange={() => handleSelectProvider(value, uuid, onChange)}
                            size={'large'}
                          />
                          {(distance || distance === 0) && (
                            <span className={css.distanceText}>{distance} km</span>
                          )}
                        </div>
                      );
                    })}
                    {countryProviders.length > DEFAULT_NUMBER_OF_PROVIDERS && (
                      <button className={css.expandBtn} onClick={handleExpandProvidersClick}>
                        <FormattedMessage
                          id={
                            isExpandedProviderList ? 'FilterModal.showLess' : 'FilterModal.showMore'
                          }
                        />
                      </button>
                    )}
                  </div>
                </section>
              );
            }}
          />
          <Controller
            control={control}
            name={FORM_FIELDS.HOME_DELIVERY}
            render={({ field: { onChange, value } }) => (
              <section>
                <p className={css.sectionTitle}>
                  <FormattedMessage id="FilterModal.deliveryOptions" />
                </p>
                <div className={css.itemContainer}>
                  <Checkbox
                    label={intl.formatMessage({ id: 'FilterModal.homeDeliver' })}
                    checked={value}
                    onChange={event => onChange(event.target.checked)}
                    size={'large'}
                  />
                </div>
              </section>
            )}
          />
          <Controller
            control={control}
            name={FORM_FIELDS.COUNTRY}
            render={({ field: { onChange, value } }) => (
              <Accordion
                title="FilterModal.country"
                containerClassName={css.accordionContainer}
                titleContainerClassName={css.accordionTitlesContainer}
              >
                <div className={css.radioContainer}>
                  {config.countryNames.map(({ countryId, country }) => (
                    <RadioButton
                      key={countryId}
                      name="country"
                      value={countryId}
                      checked={value === countryId}
                      onChange={event => {
                        setValue(FORM_FIELDS.PROVIDER, '');
                        onChange(countryId);
                      }}
                      label={intl.formatMessage({ id: country })}
                      labelClassName={css.radioBtn}
                      // textClassName={css.radioBtnText}
                      size='large'
                    />
                  ))}
                </div>
              </Accordion>
            )}
          />
          <Controller
            control={control}
            name={FORM_FIELDS.SORT}
            render={({ field: { onChange, value } }) => (
              <Accordion
                title="FilterModal.sort"
                containerClassName={css.accordionContainer}
                titleContainerClassName={css.accordionTitlesContainer}
              >
                <div className={css.boxContainer}>
                  {config.custom.sortConfig.options.map(({ key, label }) => (
                    <button
                      key={key}
                      type="button"
                      onClick={() => onChange(key)}
                      className={classNames(css.btn, {
                        [css.btnSelected]: isSelectedField(key, value),
                      })}
                      disabled={
                        RELEVANCE_VALUE === value
                          ? RELEVANCE_VALUE !== key
                          : RELEVANCE_VALUE === key
                      }
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </Accordion>
            )}
          />
        </div>
        <div className={css.buttonSection}>
          <ClearButton buttonClassName={css.btnClearDesktop} onClick={handleClearFilterClick} />
          <button
            type="submit"
            className={css.btnSearch}
            onClick={handleSubmit}
            // disabled={errors?.datesField?.message}
          >
            <FormattedMessage id="FilterModal.showResults" />
          </button>
        </div>
      </form>
    </Modal>
  );
};

FilterModal.defaultProps = {
  rootClassName: null,
  className: null,
};

FilterModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string.isRequired }).isRequired,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  closeModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  providers: array.isRequired,
};

export default compose(withRouter, injectIntl)(FilterModal);
