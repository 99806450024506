import React, { useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { func, string, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';

import {
  parse,
  decodeLatLng,
  encodeLatLng,
  encodeLatLngBounds,
  decodeLatLngBounds,
  stringify,
} from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import useLocalStorage from '../../hooks/useLocalStorage';
import businessCategoryConfig from '../../business-category-config';
import { IconSearchSecond, ValidationError } from '../../components';
import Geocoder from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import AutocompleteLocation from './AutocompleteLocation/AutocompleteLocation';
import AutocompleteListing from './AutocompleteListing/AutocompleteListing';

import css from './MainFiltersForm.css';

const NUMBER_OF_DEGREES_FROM_ORIGIN = 0.003;

const SEARCH_PAGE = 'SearchPage';
const FORM_FIELDS = {
  KEYWORDS: 'keywordsField',
  LOCATION: 'locationField',
};

const MainFiltersForm = ({
  intl,
  location,
  history,
  categoriesSuggestions,
  formClassName,
  listingInputClassName,
  listingInputFocusClassName,
  locationWrapperClassName,
  locationInputClassName,
  locationInputFocusClassName,
  buttonSearchClassName,
  formContainerClassName,
  searchIconClassName,
  searchTextClassName,
  predictionsClassName,
  suggestionsWrapperClassName,
  buttonText,
  onModalClose,
  isMobileModalOpen,
  wrapperClassName
}) => {
  const { address, origin, keywords, category, businessCategory, page, exact, isLocation, sort, ...rest } = parse(
    location.search
  );
  const subcategory = businessCategory && businessCategoryConfig.find(({ key }) => key === businessCategory);
  const textSubcategory = subcategory && intl.formatMessage({ id: subcategory.translation });
  const [lastSearches, setLastSearches] = useLocalStorage('last-searches', []);
  const formClasses = classNames(formClassName, css.formRoot);
  const formContainerClasses = classNames(formContainerClassName, css.formContainer);
  const listingInputClasses = classNames(listingInputClassName, css.listingInput);
  const listingInputFocusClasses = classNames(listingInputFocusClassName, css.listingInputFocus);
  const locationWrapperClasses = classNames(locationWrapperClassName, css.locationInput);
  const locationInputClasses = classNames(locationInputClassName, css.customSelectInput);
  const locationInputFocusClasses = classNames(locationInputFocusClassName, css.customSelectInputFocus);
  const buttonSearchClasses = classNames(buttonSearchClassName, css.btnSearch);
  const searchIconClasses = classNames(searchIconClassName, css.searchIcon);
  const searchTextClasses = classNames(searchTextClassName, css.searchText);
  const predictionsClasses = classNames(predictionsClassName, css.predictionsRoot);

  const keywordRef = useRef();
  const _geocoderRef = useRef();
  const locationRef = useRef();

  const initialValues = {
    locationField: {
      search: address || '',
      selectedPlace: {
        address: address || '',
        origin: origin ? decodeLatLng(origin) : {},
        bounds: location.state?.bounds || {},
      },
      isUserLocation: !!isLocation,
    },
    keywordsField: {
      keywords: !!exact ? ( businessCategory ? textSubcategory : '' ) : ( keywords || "" ),
      category: category || '',
      subcategory: businessCategory || '',
      exact: !!exact,
    },
  };

  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: initialValues,
  });

  const getGeocoder = () => {
    // Create the Geocoder as late as possible only when it is needed.
    if (!_geocoderRef.current) {
      _geocoderRef.current = new Geocoder();
    }
    return _geocoderRef.current;
  };

  const setLocationField = (locationValue, originValue, boundsValue) =>
    setValue(FORM_FIELDS.LOCATION, {
      search: locationValue || '',
      selectedPlace: {
        address: locationValue || '',
        origin: originValue ? decodeLatLng(originValue) : {},
        bounds: decodeLatLngBounds(boundsValue) || {},
      },
      isUserLocation: false,
    });

  const onSubmit = async data => {
    const { locationField, keywordsField } = data;
    const { selectedPlace, predictions, isUserLocation } = locationField || {};

    let firstPrediction;
    if (!selectedPlace && predictions && predictions[0]) {
      firstPrediction = await getGeocoder().getPlaceDetails(predictions[0]);
    }

    const { address: addressPrediction, bounds, origin: originPrediction } =
      selectedPlace || firstPrediction || {};

    const originMaybe =
      addressPrediction === address
        ? { origin }
        : originPrediction && !!Object.keys(originPrediction).length
        ? { origin: encodeLatLng(originPrediction) }
        : {};

    const addressMaybe = addressPrediction ? { address: addressPrediction } : {};
    const categoryMaybe = keywordsField.category ? { category: keywordsField.category } : {};
    const businessCategoryMaybe = keywordsField.subcategory
      ? { businessCategory: keywordsField.subcategory }
      : {};
    const keywordsMaybe = keywordsField.keywords ? { keywords: keywordsField.keywords } : {};
    const userLocationMaybe = isUserLocation ? { isLocation: true } : {};
    const exactMaybe = keywordsField?.exact ? { exact: true } : {};
    const sortMaybe = ( keywordsField?.exact && sort === 'relevance') || !keywordsField.keywords || keywordsField?.exact !== exact ? {} : { sort };

    const searchParams = {
      ...rest,
      ...originMaybe,
      ...addressMaybe,
      ...categoryMaybe,
      ...businessCategoryMaybe,
      ...keywordsMaybe,
      ...userLocationMaybe,
      ...exactMaybe,
      ...sortMaybe
    };

    if (selectedPlace?.address || keywordsField.keywords) {
      setLastSearches(prevState => {
        const duplicateLastSearch = prevState.find(
          item =>
            keywordsField.keywords === item.keyword &&
            keywordsField.category === item.category &&
            keywordsField.subcategory === item.subcategory &&
            keywordsField.exact === item.exact &&
            addressPrediction === item.location
        );

        if (!!duplicateLastSearch) return prevState;

        const originValue =
          addressPrediction === address
            ? origin
            : !!originPrediction && !!Object.keys(originPrediction).length
            ? encodeLatLng(originPrediction)
            : '';

        const lat = originValue && decodeLatLng(originValue).lat;
        const lng = originValue && decodeLatLng(originValue).lng;
        const calculatedBounds = originValue
          ? `${lat + NUMBER_OF_DEGREES_FROM_ORIGIN},${lng + NUMBER_OF_DEGREES_FROM_ORIGIN},${lat -
              NUMBER_OF_DEGREES_FROM_ORIGIN},${lng - NUMBER_OF_DEGREES_FROM_ORIGIN}`
          : '';
        const boundsValue =
          bounds && !!Object.keys(bounds).length ? encodeLatLngBounds(bounds) : calculatedBounds;

        const value = {
          keyword: keywordsField.keywords,
          category: keywordsField.category,
          subcategory: keywordsField.subcategory,
          location: addressPrediction,
          origin: originValue,
          bounds: boundsValue,
          exact: keywordsField.exact,
        };

        const searches = prevState.filter((item, index) => index < 4);
        return prevState.length < 5 ? [value, ...prevState] : [value, ...searches];
      });
    }

    history.push({
      pathname: createResourceLocatorString(SEARCH_PAGE, routeConfiguration(), {}, {}),
      search: stringify(searchParams),
      state: { bounds },
    });

    onModalClose();
  };

  const onKeywordsFocus = () => {
    !keywordRef.current.value && keywordRef.current.focus();
  };

  const onLocationFocus = () => {
    !locationRef.current.value && locationRef.current.focus();
  };

  const handleBlur = () => {
    const currentLocationField = getValues(FORM_FIELDS.LOCATION);
    const firstPrediction =
      !!currentLocationField?.predictions?.length &&
      currentLocationField?.predictions[0].description;

    if (!!firstPrediction && firstPrediction !== currentLocationField.search) {
      setValue(FORM_FIELDS.LOCATION, {
        ...currentLocationField,
        search: firstPrediction,
      });
    }
  };

  useEffect(() => {
    isMobileModalOpen && keywordRef.current.focus();

    const locationInput = document.getElementById('locationInput');
    locationInput.addEventListener('blur', handleBlur);

    return () => {
      locationInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClasses}>
      <div className={formContainerClasses}>
        <Controller
          control={control}
          name={FORM_FIELDS.KEYWORDS}
          render={({ field: { onChange, value } }) => (
            <div>
              <AutocompleteListing
                inputClassName={listingInputClasses}
                inputFocusClassName={listingInputFocusClasses}
                value={value}
                onChange={onChange}
                placeholder={intl.formatMessage({ id: 'SearchKeyword.search' })}
                suggestions={categoriesSuggestions}
                ref={keywordRef}
                onLocationFocus={onLocationFocus}
                setLastSearches={setLastSearches}
                lastSearches={lastSearches}
                setLocationField={setLocationField}
                intl={intl}
                suggestionsWrapperClassName={suggestionsWrapperClassName}
                wrapperClassName={wrapperClassName}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name={FORM_FIELDS.LOCATION}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { isTouched },
            formState: { isValid },
          }) => (
            <div>
              <AutocompleteLocation
                className={locationWrapperClasses}
                inputClassName={locationInputClasses}
                inputFocusClassName={locationInputFocusClasses}
                iconClassName={css.customSelectInputIcon}
                predictionsClassName={predictionsClasses}
                validClassName={css.validLocation}
                placeholder={intl.formatMessage({ id: 'MainFilters.searchCity' })}
                name={name}
                input={{
                  name,
                  onBlur,
                  onChange,
                  onFocus: () => locationRef.current.focus(),
                  value,
                }}
                useDefaultPredictions={false}
                citiesOnly
                ref={locationRef}
                setLatestSearches={setLastSearches}
                latestSearches={lastSearches}
                onKeywordsFocus={onKeywordsFocus}
                intl={intl}
              />
              <ValidationError
                fieldMeta={{
                  valid: isValid,
                  touched: isTouched,
                }}
              />
            </div>
          )}
        />
        <button className={buttonSearchClasses} type="submit">
          <IconSearchSecond className={searchIconClasses} />
          {buttonText && <span className={searchTextClasses}>{buttonText}</span>}
        </button>
      </div>
    </form>
  );
};

MainFiltersForm.defaultProps = {
  formClassName: null,
  listingInputClassName: null,
  locationWrapperClassName: null,
  locationInputClassName: null,
  buttonSearchClassName: null,
  formContainerClassName: null,
  searchIconClassName: null,
  searchTextClassName: null,
  predictionsClassName: null,
  suggestionsWrapperClassName: null,
  buttonText: null,
  onModalClose: () => {},
};

MainFiltersForm.propTypes = {
  intl: intlShape.isRequired,
  categoriesSuggestions: arrayOf(propTypes.categoriesSuggestions).isRequired,
  formClassName: string,
  listingInputClassName: string,
  locationWrapperClassName: string,
  locationInputClassName: string,
  buttonSearchClassName: string,
  formContainerClassName: string,
  searchIconClassName: string,
  searchTextClassName: string,
  predictionsClassName: string,
  suggestionsWrapperClassName: string,
  buttonText: string,
  onModalClose: func,
  location: shape({ search: string }).isRequired,
  history: shape({ push: func.isRequired }).isRequired,
};

export default MainFiltersForm;
