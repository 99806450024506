import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import moment from 'moment';

// const sortedTransactions = txs =>
//   reverse(
//     sortBy(txs, tx => {
//       return tx.attributes ? tx.attributes.lastTransitionedAt : null;
//     })
//   );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      // const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(payload.data.data),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, providerId } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const providerParam = providerId === 'me' ? { employeeId: 'me' } : { providerId };

  const { page = 1, bookingState, notResponded, phrase, startDate, endDate, nonDeliveredOnTime, deliveryState} = parse(search);
  const currentEdnDay = endDate ? moment(endDate).add(2, 'days').toDate().toISOString().split('T')[0] : undefined; 
  const owner = onlyFilter === 'order' ? { userId: 'me' } : { ...providerParam };
  const notRespondedMessagesMaybe = notResponded ? { notRespondedMessage: true } : {};

  const apiQueryParams = {
    processState: bookingState,
    ...notRespondedMessagesMaybe,
    // only: onlyFilter,
    // lastTransitions: bookingState ? bookingState === 'delivered' ? [...TRANSITIONS_TO_STATE['delivered'], ...TRANSITIONS_TO_STATE['reviewed']] : TRANSITIONS_TO_STATE[bookingState] : TRANSITIONS,
    include: ['provider', 'provider.image', 'user', 'user.image', 'listings', 'deliveryToProvider'],
    ...owner,
    page,
    per_page: INBOX_PAGE_SIZE,
    phrase,
    startFrom: startDate, 
    startTo: startDate, 
    endFrom: currentEdnDay, 
    endTo: currentEdnDay,
    nonDeliveredOnTime,
    deliveryState
  };

  if (!providerId && onlyFilter === 'sale') return Promise.resolve([]);

  return sdk.newSdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
 